export const STORAGE_KEYS = {
  ACCESS_TOKEN_KEY: `${process.env.VUE_APP_ENV}_USER_ACCESS_TOKEN_DATA`,
  USER_ID: `${process.env.VUE_APP_ENV}_USER_ID`,
};

export const SESSION_STORAGE_KEYS = {
  COUNTDOWN_KEY: `${process.env.VUE_APP_ENV}_COUNTDOWN_KEY`,
};

export const DOMAIN_OSS2 = 'https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/';
export const DOMAIN_OSS = 'https://sp.haohaolook.com/';

export const ORIGIN_SRC = 'originSrc';

export const TREND_TYPE = {
  ALL: 0, // 全部
  COMMON: 1, // 图文
  VIDEO: 2, // 视频
  WORK: 3, // 作品
  ARTICLE: 4, // 文章
};

// 入驻类型
export const BUSINESS_TYPE = {
  INDIVIDUAL: 0, // 个人
  CREATOR: 1, // 创作者
  STORE: 2, // 独立店
  BUSINESS: 3, // 商家
};

// 入驻标志
export const BUSINESS_TAG = {
  INDIVIDUAL: 1, // 个人
  ORGANIZATION: 2, // 企业
};

export const callbackUrl = process.env.VUE_APP_CALLBACK_URL;
export const appid = process.env.VUE_APP_APPID;

// 埋点
export const DOMAIN_ACTION = {
  LIKE: 1, // 喜欢
  FAVORITE: 2, // 收藏
  SHARE: 3, // 分享
  BROWSE: 4, // 浏览
  CLICK_CONTACT_INFORMATION: 5, // 点击联系方式
  GET_CONTACT_INFORMATION: 6, // 提交联系方式
};
