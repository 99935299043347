<template>
  <div class="flex column page">
    <div class="container">
      <v-title :value="businessUserTitle" />
      <router-view class="route-view" />
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { privateDomainAction, businessInfo } from '@/store';
import { DOMAIN_ACTION } from '@/constants/index';
import { getUrlCode } from '@/utils';

@Component
export default class App extends Vue {
  get businessUserTitle() {
    return businessInfo.businessUserTitle;
  }

  created() {
    businessInfo.init();
  }

  async mounted() {
    await privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.BROWSE }); // 浏览 埋点

    if (getUrlCode('isShare')) {
      privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.SHARE }); // 分享 埋点
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  font-family: "Source Han Sans CN", Arial, sans-serif;

  .container {
    width: 100%;
    max-width: $h5-max-width;
  }
}
</style>
