<template>
  <div class="page-video-detail">
    <v-title :value="title" />
    <PageLoading :on-fetch="onFetch" />
    <div class="icon-close" @click="navBack" v-html="IconBackSvg">
    </div>

    <div class="video-wrapper" @click="toggleVideoPlay">
      <img v-if="!isPlayVideo" class="play-btn-icon" lazy-load="true" src="@/assets/icon-play.svg" />

      <video v-if="videoUrl" ref="video" class="video" :poster="poster" @ended="isPlayVideo = false">
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </div>

    <div class="bottom-box">
      <div class="info">
        <div class="head" @click="$router.push({ name: 'business-home' })">
          <div class="avatar">
            <img :src="getSrc({ src: businessInfo.data.headPic })" alt="" class="image" />
          </div>
          <div class="name text-overflow">
            {{ detailStore.data.userName }}
          </div>
        </div>
        <div class="description">
          <div v-html="detailStore.data.content"></div>
        </div>
      </div>
    </div>

    <div class="tool-btns">
      <ToolBtns :id="detailStore.data.trendIssueId" />
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { businessInfo, detailStore, authStore } from '@/store';
import { getSrc } from '@/utils';
import PageLoading from '@/components/page-loading.vue';
import { omit } from 'lodash';
import { callbackUrl } from '@/constants/index';
import ToolBtns from './components/tool-btns.vue';
import IconBackSvg from '!raw-loader!@/assets/icon-back.svg';

@Component({
  components: { PageLoading, ToolBtns }
})
export default class VideoDetail extends Vue {
  businessInfo = businessInfo // 商家信息
  detailStore = detailStore
  getSrc = getSrc

  isPlayVideo = false
  trendIssueId = '';
  videoUrl = '';
  poster = '';
  title = ''
  code = ''
  IconBackSvg = IconBackSvg

  toggleVideoPlay() {
    if (this.isPlayVideo) {
      this.$refs.video?.pause();
      this.isPlayVideo = false;
    } else {
      this.$refs.video?.play();
      this.isPlayVideo = true;
    }
  }

  async mounted() {
    if (!this.$route.query.id) {
      const errMsg = '资源不存在';
      this.$showToast(errMsg);
      return Promise.reject(new Error(errMsg));
    }
  }

  async navBack() {
    if (this.code) {
      const { tab, secondTab } = this.$route.query;
      this.$router.push({ name: 'business-home', query: { tab, secondTab } });
    } else {
      this.$router.go(-1);
      await this.$nextTick();
      // 检查跳转之后路由是不是还是当前页面，如果是，返回首页，这种情况会出现在详情页进入=>H5返回=>使用浏览器返回详情页
      if (this.$router.currentRoute.name === 'label-detail') {
        this.$router.push({ name: 'business-home' });
      }
    }
  }

  async onFetch() {
    this.code = this.$route.query.code;
    if (this.code) {
      await authStore.login();
      this.$router.replace({ query: omit(this.$route.query, 'code') });
    }
    this.trendIssueId = this.$route.query.id;

    try {
      await authStore.tryFetchData();
    } catch (err) {
      if ([20004, 20005].includes(Number(err.code))) {
        const callback = callbackUrl + this.$router.resolve({ name: 'video-detail', query: { id: this.trendIssueId, title: this.title } }).href;
        return await authStore.checkLogin(callback);
      }
    }

    await Promise.all([
      businessInfo.tryFetchData(),
      detailStore.fetchData({ trendIssueId: this.$route.query.id })
    ]);
    this.title = businessInfo.data.name || this.$route.query.title;
    this.videoUrl = getSrc({ src: detailStore.data.trendContents[0]?.uri, isAvatar: false });
    this.poster = getSrc({ src: detailStore.data.coverUri, isAvatar: false });
    const shareQuery = {
      isShare: 1,
      id: this.trendIssueId,
      ...businessInfo.shareQuery
    };
    this.wxShare({ shareParams: {
      title: shareQuery.title,
      timelineTitle: detailStore.data.title,
      link: `${location.origin}${this.$router.resolve({ name: 'video-detail', query: shareQuery }).href}`,
      desc: detailStore.data.title,
      imgUrl: this.poster
    } });
  }
}
</script>

<style lang="scss" scoped>
.page-video-detail {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: $primary-color;

  .icon-close {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    margin: 12px;

    .icon {
      width: 100%;
    }
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    max-height: 80vh;

    .play-btn-icon {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      width: 30px;
      transform: translate(-50%, -50%);
    }

    .video {
      width: 100%;
      max-height: 80vh;
      object-fit: cover;
    }
  }

  .bottom-box {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    color: #fff;

    .info {
      padding: 12px;

      .head {
        display: flex;
        align-items: center;
        gap: 6px;

        .avatar {
          box-sizing: border-box;
          width: 38px;
          height: 38px;
          border: 1px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          background-color: #f0f0f0;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          flex: 1;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .description {
        max-height: 20vh;
        margin-top: 12px;
        overflow: scroll;
        font-size: 16px;
        line-height: 26px;
        white-space: pre-line;
        letter-spacing: 0.32px;
      }
    }
  }
}

.tool-btns {
  position: fixed;
  z-index: 10;
  bottom: 29%;
  right: calc((100vw - min(#{$h5-max-width}, 100vw)) / 2 + 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 136px;
  border-radius: 45px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}
</style>
