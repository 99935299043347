<template>
  <div class="page-label-detail">
    <v-title :value="title" />
    <PageLoading :on-fetch="onFetch" :forceLoading="isLoading" />

    <div class="page-label">
      <div class="icon-close" @click="navBack" v-html="IconBackSvg">
      </div>
      <van-swipe class="my-swipe" indicator-color="#1e1e1e" @change="onChange" v-model="current" :initial-swipe="current">
        <van-swipe-item v-for="(img, index) in imgList" :key="index">
          <div class="swiper-image" :class="{ 'mult': imgList.length > 1 }">
            <img :src="img" alt="" class="image" @load="isLoading = false" />
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator" v-if="imgList.length > 1">
            <span v-for="(img, index) in imgList" :key="index" :class="{ 'active': index === current }"
              class="item" @click="current = index"></span>
          </div>
        </template>
      </van-swipe>

      <div class="content">
        <div class="head" @click="$router.push({ name: 'business-home' })">
          <div class="avatar">
            <img :src="getSrc({ src: businessInfo.data.headPic })" alt="" class="image" />
          </div>
          <div class="name text-overflow">
            {{ businessInfo.data.name }}
          </div>
        </div>
        <div class="title">
          {{ detailStore.data.title }}
        </div>
        <div v-if="[TREND_TYPE.COMMON, TREND_TYPE.VIDEO].includes(Number(detailStore.data.contentType))">
          {{ detailStore.data.content }}
        </div>
        <div v-if="[TREND_TYPE.ARTICLE, TREND_TYPE.WORK].includes(Number(detailStore.data.contentType))" class="html"
          v-html="htmlContent">
        </div>
      </div>

      <div class="inquiry-wrapper">
        <Inquiry :head-src="getSrc({ src: businessInfo.data?.contactInfos?.[0].headImage })"
          @contact-click="onHandleInquiry" />
      </div>

      <div class="tool-btns">
        <ToolBtns :id="detailStore.data.trendIssueId" />
      </div>
    </div>

    <!-- 销售信息弹框 -->
    <ContactInfoPopup :show="showSaleInfoPopup" :contact-info="businessInfo.data.contactInfos"
      @update:show="showSaleInfoPopup = $event" />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { getSrc } from '@/utils';
import { businessInfo, detailStore, authStore, submitContact, privateDomainAction } from '@/store';
import { DOMAIN_ACTION, TREND_TYPE, callbackUrl } from '@/constants/index';
import defaultAvatar from '@/assets/head.svg';
import PageLoading from '@/components/page-loading.vue';
import Inquiry from '@/components/inquiry.vue';
import ContactInfoPopup from '@/components/contact-info-popup.vue';
import { omit } from 'lodash';
import ToolBtns from './components/tool-btns.vue';
import IconBackSvg from '!raw-loader!@/assets/icon-back.svg';

@Component({
  components: { PageLoading, Inquiry, ContactInfoPopup, ToolBtns }
})
export default class LabelDetail extends Vue {
  businessInfo = businessInfo
  detailStore = detailStore
  defaultAvatar = defaultAvatar
  getSrc = getSrc
  current = 0

  showSaleInfoPopup = false
  trendIssueId = ''
  DOMAIN_ACTION = DOMAIN_ACTION
  TREND_TYPE = TREND_TYPE
  title = ''
  code = ''
  isLoading = true
  IconBackSvg = IconBackSvg

  async mounted() {
    if (!this.$route.query.id) {
      const errMsg = '资源不存在';
      this.$showToast(errMsg);
      return Promise.reject(new Error(errMsg));
    }
  }

  async navBack() {
    if (this.code) {
      const { tab, secondTab } = this.$route.query;
      this.$router.push({ name: 'business-home', query: { tab, secondTab } });
    } else {
      this.$router.go(-1);
      await this.$nextTick();
      // 检查跳转之后路由是不是还是当前页面，如果是，返回首页，这种情况会出现在详情页进入=>H5返回=>使用浏览器返回详情页
      if (this.$router.currentRoute.name === 'label-detail') {
        this.$router.push({ name: 'business-home' });
      }
    }
  }

  onChange(index) {
    this.current = index;
  }

  async onFetch() {
    this.code = this.$route.query.code;
    if (this.code) {
      await authStore.login();
      this.$router.replace({ query: omit(this.$route.query, 'code') });
    }

    this.trendIssueId = this.$route.query.id;

    try {
      await authStore.tryFetchData();
    } catch (err) {
      if ([20004, 20005].includes(Number(err.code))) {
        const query = {
          ...this.$route.query,
          id: this.trendIssueId,
          title: this.title
        };
        const callback = callbackUrl + this.$router.resolve({ name: 'label-detail', query }).href;
        return await authStore.checkLogin(callback);
      }
    }

    await Promise.all([
      businessInfo.tryFetchData(),
      detailStore.fetchData({ trendIssueId: this.$route.query.id })
    ]);
    this.title = detailStore.data.title || this.$route.query.title;
    if (this.$route.query.showSaleInfo) {
      this.showSaleInfoPopup = true;
      this.$router.replace({ query: omit(this.$route.query, 'showSaleInfo') });
    }
    const shareQuery = {
      isShare: 1,
      id: this.trendIssueId,
      ...businessInfo.shareQuery
    };
    this.wxShare({ shareParams: {
      title: shareQuery.title,
      timelineTitle: detailStore.data.title, //朋友圈标题
      link: `${location.origin}${this.$router.resolve({ name: 'label-detail', query: shareQuery }).href}`,
      desc: detailStore.data.title,
      imgUrl: getSrc({ src: detailStore.data.coverUri, suffix: '?x-oss-process=image/resize,h_400,m_lfit/format,jpg', isAvatar: false }),
    } });
  }

  get imgList() {
    if ([TREND_TYPE.WORK, TREND_TYPE.ARTICLE].includes(detailStore.data.contentType)) {
      return [getSrc({ src: detailStore.data.coverUri, suffix: '?x-oss-process=image/resize,h_400,m_lfit/format,jpg', isAvatar: false })];
    } else {
      return (detailStore.data.trendContents || []).map(item => getSrc({ src: item.appletComprImage, isAvatar: false }));
    }
  }

  get htmlContent() {
    const html = detailStore.data.content;
    let newContent = html.replace(/<img[^>]*>/gi, function (match) {
      match = match.replace(/(style|width|height)=("[^"]*"|'[^']*')/gi, '');
      match = match.replace('style=""', '');
      return match;
    });
    newContent = newContent.replace(
      /style="[^"]+"/gi,
      function (match) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;');
        return match;
      }
    );
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(
      /<img/gi,
      '<img style="width:100%;height:auto;display:block;margin:10px 0;border-radius:12px;margin-top:16px"'
    );
    return newContent;
  }

  async onHandleInquiry() {
    privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.CLICK_CONTACT_INFORMATION }); // 触发 点击联系方式 埋点

    if (!businessInfo.data.contactInfos.length) {
      this.$showToast('暂无销售信息，敬请期待...');
      return;
    }

    try {
      if (businessInfo.data.haveContacted) { // 已提交客户信息
        this.showSaleInfoPopup = true;
        return;
      }
      if (authStore.data.phone) { // 有手机号
        await submitContact.fetchData();
        this.showSaleInfoPopup = true;
        return;
      }
      this.$router.push({ name: 'authorization', query: { type: 'detail', id: this.trendIssueId } });
    } catch (err) {
      if (err.code === '40040') { // 重复提交联系方式
        this.showSaleInfoPopup = true;
        businessInfo.fetchData();
      } else {
        this.$showToast(err.message);
      }
      throw err;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-label-detail {
  height: 100vh;
  overflow: scroll;

  &:deep(.van-swipe__indicator) {
    background-color: #f0f0f0;
    opacity: 1
  }

  .page-label {
    position: relative;
    height: 100%;
    overflow: scroll;
  }

  .icon-close {
    position: absolute;
    z-index: 1;
    width: 36px;
    height: 36px;
    margin: 12px;
  }

  .my-swipe {
    padding-bottom: 10px;
  }

  .swiper-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #191919;

    &.mult {
      height: 60vh;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .custom-indicator {
    display: flex;
    gap: 6px;
    justify-content: center;
    padding: 10px 0 0;

    .item {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #f0f0f0;

      &.active {
        background-color: #191919;
      }
    }
  }

  .content {
    padding: 0 14px 80px;
    font-size: 16px;
    line-height: 26px;
    white-space: pre-line;
    letter-spacing: 0.32px;
    color: #1e1e1e;

    .head {
      display: flex;
      align-items: center;
      gap: 6px;

      .avatar {
        width: 38px;
        height: 38px;
        border: 1px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        background-color: #f0f0f0;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        flex: 1;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #1e1e1e;
      }
    }

    .title {
      margin: 10px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.36px;
      color: #1e1e1e;
    }
  }

  .inquiry-wrapper {
    position: fixed;
    z-index: 10;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: $h5-max-width;
    padding: 21px 16px;
  }

  .tool-btns {
    position: fixed;
    z-index: 10;
    bottom: 19%;
    right: calc((100vw - min(#{$h5-max-width}, 100vw)) / 2 + 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 136px;
    border-radius: 45px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  }

  &:deep(.swiper-wrapper) {
    align-items: center;
  }
}
</style>
